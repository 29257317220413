<template>
	<v-sheet class="group" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="3" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.group_status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="9" class="py-1 my-auto text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-layout class="justify-end">
						<div v-if="false" class="mx-2" style="max-width: 300px">
							<date-range-picker
								v-on:click:clear="
									s_search['date-range'] = [];
									searchOrders();
								"
								hide-details
								hide-top-margin
								clearable
								:disabled="pageLoading"
								v-model="s_search['date-range']"
							></date-range-picker>
						</div>
						<div v-if="false" class="mx-2" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									s_search['payment-by'] = [];
									searchOrders();
								"
								clearable
								:items="supplier_list"
								hide-details
								custom-class="pt-0"
								placeholder="Payment By"
								multiple
								:disabled="pageLoading"
								v-model="s_search['payment-by']"
							></select-input>
						</div>
						<div v-if="false" class="mx-2" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									s_search['type'] = [];
									searchOrders();
								"
								clearable
								:items="item_type_items"
								hide-details
								custom-class="pt-0"
								placeholder="Type"
								multiple
								:disabled="pageLoading"
								v-model="s_search['type']"
							></select-input>
						</div>
						<div v-if="false" class="mx-2" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									s_search['acknowledge'] = [];
									searchOrders();
								"
								clearable
								:items="acknowledge_items"
								hide-details
								custom-class="pt-0"
								placeholder="Acknowledge Status"
								multiple
								:disabled="pageLoading"
								v-model="s_search['acknowledge']"
							></select-input>
						</div>
						<div class="mx-2" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									s_search['cost-center'] = [];
									searchOrders();
								"
								clearable
								hide-top-margin
								:items="cost_center_items"
								hide-details
								:disabled="pageLoading"
								custom-class="pt-0"
								placeholder="Cost Center"
								multiple
								v-model="s_search['cost-center']"
							></select-input>
						</div>
						<div class="d-flex">
							<v-btn
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								:disabled="pageLoading"
								v-on:click="searchOrders()"
								><v-icon center> mdi-magnify </v-icon></v-btn
							>
							<v-btn
								v-if="getPermission('payment::create')"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								:disabled="pageLoading"
								v-on:click="select_invoice_dialog = true"
								><v-icon left> mdi-plus </v-icon> Create</v-btn
							>
							<v-menu
								v-if="getPermission('setting::edit')"
								bottom
								left
								origin="center center"
								transition="slide-y-transition"
								rounded="0"
								offset-y
								max-height="400px"
								:close-on-content-click="false"
								content-class="white-background"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										color="blue darken-4"
										class="text-white"
										tile
										:disabled="pageLoading"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon>mdi-table-edit</v-icon>
									</v-btn>
								</template>
								<Draggable
									tag="ul"
									v-model="draggableThead"
									class="draggable-group"
									handle=".draggable-drag-icon"
									v-on:change="updateTable('group')"
								>
									<template v-for="cols in draggableThead">
										<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
											<v-checkbox
												dense
												v-model="draggableTheadShow"
												v-bind:value="cols.key"
												:label="cols.name"
												:disabled="cols.fixed || pageLoading"
												color="blue"
												hide-details
												class="mt-0 mb-0"
												v-on:change="updateTableVisiblity('group')"
											></v-checkbox>
											<v-icon
												v-if="!pageLoading && !cols.fixed"
												class="draggable-action draggable-drag-icon"
												right
												color="blue"
												>mdi-drag</v-icon
											>
											<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
										</li>
									</template>
								</Draggable>
							</v-menu>
							<v-btn
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								:disabled="pageLoading"
								v-on:click="export_payments()"
								><v-icon> mdi-file-excel-outline </v-icon></v-btn
							>
							<v-btn
								:disabled="pageLoading"
								color="blue darken-4"
								class="text-white"
								v-on:click="exportDialogNew = true"
								tile
								depressed
							>
								<v-icon dark left>mdi-database-export</v-icon> Export
							</v-btn>
						</div>
					</v-layout>
				</template>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Payment #, Invoice #, Invoice Number, Remark"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="payment"
			delete-endpoint="payment/"
			:dialog-update="true"
			:dialog-view="true"
			v-on:reload:content="filterRows"
			v-on:update:dialog="updateDialog($event)"
			v-on:view:dialog="viewDialog($event)"
			delete-note="All transactions of this payment will also be deleted."
		></Table>
		<ExportDialogNew
			endpoint="payments/export?type=payment"
			title="Payments"
			:cols="draggableThead"
			:export-dialog.sync="exportDialogNew"
			v-on:close="exportDialogNew = false"
		></ExportDialogNew>
		<Dialog :dialog="payment_dialog" :dialog-width="dialogWidth" dense>
			<template v-slot:title>
				<v-layout>
					<v-flex md6 class="my-auto">
						<div class="d-flex align-items-center">
							<span class="mr-4">Payment</span>
							<Chip
								tooltip
								tooltip-text="Payment #"
								class="mr-4"
								:text="payment_detail.barcode"
								color="blue darken-4 white--text"
							></Chip>
						</div>
					</v-flex>
					<v-flex md6 class="text-right">
						<v-btn v-on:click="payment_dialog = false" class="ml-2" depressed tile
							><v-icon small left>mdi-close</v-icon>Close</v-btn
						>
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<div>
					<v-tabs
						v-model="paymentTab"
						background-color="transparent"
						color="blue"
						class="custom-tab-transparent"
						active-class="blue darken-4 text-white"
						hide-slider
						style="position: sticky; top: 0; z-index: 99"
					>
						<v-tab href="#detail">
							<span class="tab-svg-icon mr-2">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/services.svg')" />
								<!--end::Svg Icon-->
							</span>
							Details
						</v-tab>
						<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab>
						<v-tab href="#comment"> <v-icon small left>mdi-comment-multiple</v-icon> Comments</v-tab>
					</v-tabs>
					<v-tabs-items v-model="paymentTab" style="min-height: calc(100vh - 300px)">
						<v-tab-item value="detail">
							<div class="mx-4 mb-4">
								<div class="overflow-y">
									<v-expansion-panels v-model="expansion_panel" mandatory>
										<v-expansion-panel>
											<v-expansion-panel-header>
												<div class="d-flex align-items-center">
													<span class="font-level-3-bold mr-4">Payment</span>
													<Chip
														tooltip
														tooltip-text="Payment #"
														class="mr-4"
														:text="ObjectKey(payment_detail, 'barcode', 'N/A')"
														color="blue darken-4 white--text"
													></Chip>
												</div>
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<table
													width="100%"
													class="detail-table scattered-table mt-3"
													style="table-layout: fixed"
												>
													<tr>
														<th class="p-2" width="150px">Amount Received</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="p_amount_formatted"
																label="amount received"
															></ShowValue>
														</td>
														<th class="p-2" width="150px">Payment Mode</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="p_mode"
																label="payment mode"
															></ShowValue>
														</td>
														<th class="p-2" width="150px">Payment Date</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="p_date_formatted"
																label="payment date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="150px">Created At</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="added_at_formatted"
																label="created at"
															></ShowValue>
														</td>
														<th class="p-2" width="150px">Created By</th>
														<td class="font-level-1 p-2" colspan="3">
															<ShowValue
																:object="payment_detail"
																object-key="created_by.display_name"
																label="created by"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="150px">Remark</th>
														<td class="font-level-1 p-2" colspan="5">
															<ShowValue :object="payment_detail" object-key="remark" label="remark"></ShowValue>
														</td>
													</tr>
												</table>
											</v-expansion-panel-content>
										</v-expansion-panel>
										<v-expansion-panel>
											<v-expansion-panel-header>
												<div class="d-flex align-items-center">
													<span class="font-level-3-bold mr-4">Invoice</span>
													<template v-if="ObjectHasKey(payment_detail, 'invoice_relation.id')">
														<Chip
															tooltip
															tooltip-text="Invoice #"
															class="mr-4"
															:text="ObjectKey(payment_detail, 'invoice_relation.barcode', 'N/A')"
															color="blue darken-4 white--text"
														></Chip>
														<Chip
															tooltip
															tooltip-text="Invoice Status"
															class="mr-4"
															:text="ObjectKey(payment_detail, 'invoice_relation.status_relation.text', 'N/A')"
															:color="ObjectKey(payment_detail, 'invoice_relation.status_relation.color', 'N/A')"
															:text-color="
																ObjectKey(payment_detail, 'invoice_relation.status_relation.textcolor', 'N/A')
															"
														></Chip>
														<Chip
															tooltip
															tooltip-text="Invoice Approval"
															v-if="ObjectKey(payment_detail, 'invoice_relation.is_approved', 0) == 1"
															:text="ObjectKey(payment_detail, 'invoice_relation.approve_status_formatted', 'N/A')"
															:color="
																ObjectKey(payment_detail, 'invoice_relation.approve_status', 0) == 1
																	? 'green lighten-1 white--text'
																	: 'red lighten-1 white--text'
															"
														></Chip>
													</template>
												</div>
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<table
													width="100%"
													class="detail-table scattered-table mt-3"
													style="table-layout: fixed"
												>
													<tr>
														<th class="p-2" width="250px">Invoice Number</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="invoice_relation.invoice_id"
																label="invoice number"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Invoice Date</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="invoice_relation.date_formatted"
																label="Invoice Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Total Amount</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="invoice_relation.total_amount_formatted"
																label="total amount"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Paid Amount</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="invoice_relation.paid_amount_formatted"
																label="paid amount"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Created At</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="invoice_relation.added_at_formatted"
																label="created at"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Created By</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="invoice_relation.created_by.display_name"
																label="created by"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Remark</th>
														<td class="font-level-1 p-2" colspan="3">
															<ShowValue
																:object="payment_detail"
																object-key="invoice_relation.remark"
																label="remark"
															></ShowValue>
														</td>
													</tr>
												</table>
											</v-expansion-panel-content>
										</v-expansion-panel>
										<v-expansion-panel>
											<v-expansion-panel-header>
												<div class="d-flex align-items-center">
													<span class="font-level-3-bold mr-4">Service</span>
													<Chip
														tooltip
														tooltip-text="Service #"
														class="mr-4"
														:text="ObjectKey(payment_detail, 'service_relation.barcode', 'N/A')"
														color="blue darken-4 white--text"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Service Type"
														class="mr-4"
														:text="ObjectKey(payment_detail, 'service_relation.service_type_formatted', 'N/A')"
														:color="
															ObjectKey(payment_detail, 'service_relation.service_type', 'N/A') == 'on-site'
																? 'brown darken-4'
																: 'blue-grey darken-4'
														"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Service By"
														class="mr-4"
														:text="ObjectKey(payment_detail, 'service_relation.service_by_formatted', 'N/A')"
														color="blue darken-4 white--text"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Service Invoiced"
														class="mr-4"
														v-if="ObjectKey(payment_detail, 'service_relation.is_invoiced', 0) == 1"
														text="Invoiced"
														color="orange darken-2"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Service Status"
														:color="ObjectKey(payment_detail, 'service_relation.status_color', 'N/A')"
														:text="ObjectKey(payment_detail, 'service_relation.status_text', 'N/A')"
													></Chip>
												</div>
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<table
													width="100%"
													class="detail-table scattered-table mt-3"
													style="table-layout: fixed"
												>
													<tr>
														<th class="p-2" width="250px">Service Type</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="service_relation.service_type_formatted"
																label="service type"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Service Start Date (expected)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="service_relation.expected_start_date_formatted"
																label="service start date (expected)"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Service Performed by</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="service_relation.service_by_formatted"
																label="Service Performed by"
															></ShowValue>
															(<ShowValue
																:object="payment_detail"
																v-if="ObjectKey(payment_detail, 'service_relation.service_by', 0) == 1"
																object-key="service_relation.engineer.display_name"
																label="engineer"
															></ShowValue>
															<ShowValue
																:object="payment_detail"
																v-else
																object-key="service_relation.subcon.display_name"
																label="subcon"
															></ShowValue
															>)
														</td>
														<th class="p-2" width="250px">Service End Date (expected)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="service_relation.expected_end_date_formatted"
																label="Service End Date (expected)"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Service Start Date (actual)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="service_relation.actual_start_date_formatted"
																label="service start date (actual)"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Service End Date (actual)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="service_relation.actual_end_date_formatted"
																label="Service End Date (actual)"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Remark</th>
														<td class="font-level-1 p-2" colspan="3">
															<ShowValue
																:object="payment_detail"
																object-key="service_relation.description"
																label="Remark"
															></ShowValue>
														</td>
													</tr>
												</table>
											</v-expansion-panel-content>
										</v-expansion-panel>
										<v-expansion-panel>
											<v-expansion-panel-header>
												<div class="d-flex align-items-center">
													<span class="font-level-3-bold mr-4">Asset</span>
													<Chip
														v-if="false"
														tooltip
														tooltip-text="Asset #"
														class="mr-4"
														:text="ObjectKey(payment_detail, 'asset_relation.barcode', 'N/A')"
														color="blue darken-4 white--text"
													></Chip>
												</div>
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<table
													width="100%"
													class="detail-table scattered-table mt-3"
													style="table-layout: fixed"
												>
													<tr>
														<th class="p-2" width="300px">Asset ID</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.id_number"
																label="Asset ID"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Asset Name</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.name"
																label="Asset Name"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">System Owner</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.system_owner"
																label="System Owner"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Department</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.department"
																label="Department"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Manufacturer</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.manufacturer"
																label="Manufacturer"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Location</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.location"
																label="Location"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Model No.</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.model_no"
																label="Model No."
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Serial No.</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.serial_no"
																label="Serial No."
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Calibration Frequency (in months)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.calibration_frequency"
																label="Calibration Frequency"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Last Calibration Date</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.last_calibration_date_formatted"
																label="Last Calibration Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Reminder Date</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.reminder_formatted"
																label="Reminder Date"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Next Calibration Date</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.next_calibration_date_formatted"
																label="Next Calibration Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Calibration Point And Tolerance</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.calibration_point_and_tolerance"
																label="Calibration Point And Tolerance"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Cost Centre (SGD)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.cost_centre_formatted"
																label="Cost Centre"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Calibration Location</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.calibration_location"
																label="Calibration Location"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Previous Vendor</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.previous_vendor"
																label="Previous Vendor"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">AML Proposal</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.aml_proposal"
																label="AML Proposal"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Contract Price/Quote Price (SGD)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.price_formatted"
																label="Contract Price/Quote Price"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">BPO/PO Number</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.bpo_po_number"
																label="BPO/PO Number"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Frequency Per Year</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.frequency_per_year"
																label="Frequency Per Year"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Price Per Annum (SGD)</th>
														<td class="font-level-1 p-2" colspan="3">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.price_per_annum_sgd_formatted"
																label="Price Per Annum"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Process Range</th>
														<td class="font-level-1 p-2" colspan="3">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.process_range"
																label="Process Range"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Comment</th>
														<td class="font-level-1 p-2" colspan="3">
															<ShowValue
																:object="payment_detail"
																object-key="asset_relation.comment"
																label="Comment"
															></ShowValue>
														</td>
													</tr>
												</table>
											</v-expansion-panel-content>
										</v-expansion-panel>
									</v-expansion-panels>
								</div>
							</div>
						</v-tab-item>
						<v-tab-item value="file">
							<Files
								:reload="reload_now"
								v-on:reload="reload_now = false"
								class="mx-4"
								type-text="Payment Files"
								type="payment"
								:type-uuid="payment_detail.uuid"
							></Files>
						</v-tab-item>
						<v-tab-item value="comment">
							<Comments
								class="mx-4"
								type-text="Payment"
								type="payment"
								:type-uuid="payment_detail.uuid"
							></Comments>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</template>
		</Dialog>
		<Dialog :dialog="approve_dialog">
			<template v-slot:title>Payment</template>
			<template v-slot:body>
				<div class="text-center">
					<p class="font-level-3-bold my-5">Are you sure, you want to approve the payment?</p>
					<v-layout>
						<v-flex md4>
							<label for="gr-date" class="btx-label mt-4 required">GR Date</label>
						</v-flex>
						<v-flex md8>
							<DatePicker
								hide-details
								:disabled="status_loading"
								:loading="status_loading"
								id="gr-date"
								placeholder="GR Date"
								v-model="gr_date"
								:class="{ required: !gr_date }"
							></DatePicker>
						</v-flex>
					</v-layout>
					<br />
				</div>
			</template>
			<template v-slot:action>
				<v-btn
					:loading="status_loading"
					:disabled="status_loading"
					depressed
					color="blue darken-4 white--text"
					tile
					v-on:click="updateStatus('approve')"
				>
					Yes! Approve
				</v-btn>
				<v-btn depressed tile :disabled="status_loading" v-on:click="approve_dialog = false">
					No! Close
				</v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="reject_dialog">
			<template v-slot:title>Payment</template>
			<template v-slot:body>
				<div class="text-center">
					<p class="font-level-3-bold my-5">Are you sure, you want to reject the payment?</p>
					<v-layout>
						<v-flex md4>
							<label for="gr-date" class="btx-label mt-5">Remark</label>
						</v-flex>
						<v-flex md8>
							<TextAreaInput
								hide-details
								:disabled="status_loading"
								:loading="status_loading"
								id="reject-remark"
								placeholder="Remark"
								v-model="remark"
							></TextAreaInput>
						</v-flex>
					</v-layout>
				</div>
			</template>
			<template v-slot:action>
				<v-btn
					:loading="status_loading"
					:disabled="status_loading"
					depressed
					color="red lighten-1 white--text"
					tile
					v-on:click="updateStatus('reject')"
				>
					Yes! Reject
				</v-btn>
				<v-btn depressed tile :disabled="status_loading" v-on:click="reject_dialog = false">
					No! Close
				</v-btn>
			</template>
		</Dialog>
		<SelectInvoice
			v-on:select="select_invoice($event)"
			v-on:close="select_invoice_dialog = false"
			:dialog="select_invoice_dialog"
		></SelectInvoice>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import SelectInput from "@/view/components/SelectInput";
import SelectInvoice from "@/view/components/Select-Invoice";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import DateRangePicker from "@/view/components/DateRangePicker";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import Dialog from "@/view/components/Dialog";
import Chip from "@/view/components/Chip";
import ObjectPath from "object-path";
import { isString } from "lodash";
import JwtService from "@/core/services/jwt.service";

export default {
	name: "payment-listing",
	title: "Listing Payment",
	mixins: [ListingMixin],
	data() {
		return {
			uuid: null,
			gr_date: null,
			remark: null,
			paymentTab: "detail",
			payment_detail: {},
			select_invoice_dialog: false,
			status_loading: false,
			approve_dialog: false,
			reject_dialog: false,
			payment_dialog: false,
			reload_now: false,
			expansion_panel: 0,
			supplier_list: [
				{ text: "Engineer", value: "engineer" },
				{ text: "Subcon", value: "subcon" },
			],
			item_type_items: [
				{ text: "On Site", value: "on-site" },
				{ text: "In Lab", value: "in-lab" },
			],
			acknowledge_items: [
				{ text: "Approved", value: "approved" },
				{ text: "Rejected", value: "rejected" },
			],
			cost_center_items: [],
			s_search: {
				"date-range": [],
				"payment-by": [],
				type: [],
				acknowledge: [],
			},
			pageTitle: "Payments",
			pageBreadcrumbs: [{ text: "Payments", disabled: true }],
			endpoint: "payment",
			defaultFilter: {},
			menuSearch: false,
			search: null,
		};
	},
	methods: {
		export_payments() {
			let token = JwtService.getToken();
			const url = `${this.$apiURL()}export/payment?export=1&status=all&t=${new Date().getTime()}&tz=${new Date().getTime()}&token=${token}`;
			window.open(url, "_blank");
		},
		select_invoice(param) {
			this.$router.push({
				name: "payment-create",
				query: { invoice: param, t: new Date().getTime() },
			});
		},
		updateStatus(status) {
			if (status == "approve") {
				if (!this.gr_date) {
					this.$store.commit(SET_ERROR, [{ model: true, message: "Select GR Date" }]);
					return false;
				}
			}

			this.status_loading = true;

			ApiService.patch(`payment/${this.uuid}/status`, { status })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [{ model: true, message: `Success ! Payment ${status}ed.` }]);
					this.approve_dialog = false;
					this.reject_dialog = false;
					this.payment_detail = {};
					this.payment_dialog = false;
					this.getListing();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.status_loading = false;
				});
		},
		updateDialog(uuid) {
			this.$router.push({
				name: "payment-update",
				params: { uuid },
				query: { t: new Date().getTime() },
			});
		},
		getPaymentDetail() {
			this.pageLoading = true;
			this.expansion_panel = 0;
			ApiService.get(`payment/${this.uuid}`)
				.then(({ data }) => {
					this.payment_detail = data;
					this.payment_dialog = true;
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		viewDialog(uuid) {
			this.uuid = uuid;
			this.getPaymentDetail();
		},
		searchOrders() {
			this.$router
				.replace({
					name: this.$route.name,
					query: {
						...this.$route.query,
						...this.s_search,
					},
				})
				.then(() => {
					this.getListing();
				})
				.catch(() => {});
		},
	},
	components: {
		SelectInvoice,
		DatePicker,
		SelectInput,
		DateRangePicker,
		TextAreaInput,
		ShowValue,
		Chip,
		Dialog,
		Comments,
		Files,
	},
	computed: {
		...mapGetters(["tbody", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 80);
		},
	},
	mounted() {
		this.cost_center_items = this.localDB("cost_center", []);

		const date_range = ObjectPath.get(this.$route, "query.date-range", []);
		if (isString(date_range)) {
			this.s_search["date-range"] = [date_range];
		} else {
			this.s_search["date-range"] = date_range;
		}

		const payment_by = ObjectPath.get(this.$route, "query.payment-by", []);
		if (isString(payment_by)) {
			this.s_search["payment-by"] = [payment_by];
		} else {
			this.s_search["payment-by"] = payment_by;
		}

		const type = ObjectPath.get(this.$route, "query.type", []);
		if (isString(type)) {
			this.s_search["type"] = [type];
		} else {
			this.s_search["type"] = type;
		}

		const acknowledge = ObjectPath.get(this.$route, "query.acknowledge", []);
		if (isString(acknowledge)) {
			this.s_search["acknowledge"] = [acknowledge];
		} else {
			this.s_search["acknowledge"] = acknowledge;
		}

		const cost_center = ObjectPath.get(this.$route, "query.cost-center", []);
		if (isString(cost_center)) {
			this.s_search["cost-center"] = [cost_center];
		} else {
			this.s_search["cost-center"] = cost_center;
		}
	},
};
</script>
