<template>
	<Dialog :dialog="dialog" :dialogWidth="dialogWidth" dense>
		<template v-slot:title> Select Invoice </template>
		<template v-slot:body>
			<v-container fluid class="px-4">
				<template>
					<v-row>
						<v-col md="12">
							<v-layout>
								<v-flex md11>
									<TextInput
										:disabled="dataLoading"
										:loading="dataLoading"
										v-model="search"
										hide-details
										placeholder="Search..."
									></TextInput>
								</v-flex>
								<v-flex v-if="search">
									<v-btn
										:disabled="dataLoading"
										v-on:click="
											search = null;
											getInvoices();
										"
										tile
										depressed
										class="mt-3"
										color="red lighten-1 white--text"
									>
										<v-icon>mdi-close</v-icon> Cancel</v-btn
									>
								</v-flex>
								<v-flex>
									<v-btn
										:disabled="dataLoading"
										v-on:click="getInvoices()"
										tile
										depressed
										class="mt-3"
										color="blue darken-4 white--text"
									>
										<v-icon>mdi-magnify</v-icon> Search</v-btn
									>
								</v-flex>
							</v-layout>
						</v-col>
						<v-col md="12">
							<div class="bt-table">
								<table width="100%">
									<template v-if="dataLoading">
										<tr>
											<td colspan="2" align="center" height="93.75px" class="border-light-grey">
												<div class="py-2">
													<v-progress-circular indeterminate color="blue darken-4"></v-progress-circular>
													<p class="mb-0 mt-2 font-level-3 bold-600">Please wait...</p>
												</div>
											</td>
										</tr>
									</template>
									<template v-else>
										<template v-if="invoiceList.length">
											<thead>
												<tr class="border-light-grey">
													<th class="px-4 py-2">Invoice</th>
													<th class="px-4 py-2">Asset</th>
													<th class="px-4 py-2">Service</th>
												</tr>
											</thead>
											<tbody>
												<tr
													v-on:click="selectInvoice(data)"
													v-for="(data, index) in invoiceList"
													:key="index"
													class="cursor-pointer border-light-grey"
												>
													<td class="px-4 py-2">
														<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
															<Chip
																tooltip
																tooltip-text="Invoice #"
																small
																:text="data.barcode"
																color="blue darken-4"
															></Chip>
															<Chip
																tooltip
																tooltip-text="Invoice Number"
																small
																:text="data.invoice_id"
																class="mt-1"
																color="orange darken-2"
															></Chip>
															<Chip
																tooltip
																tooltip-text="Invoice Status"
																small
																class="mt-1"
																:text="data.status_relation.text"
																:color="data.status_relation.color"
															></Chip>
														</div>
													</td>
													<td class="px-4 py-2">
														<div class="d-flex align-center">
															<div>
																<ImageTemplate
																	circle
																	style="max-width: 50px"
																	:src="data.asset_relation.image"
																></ImageTemplate>
															</div>
															<div class="ml-2">
																<p v-if="false" class="mb-0">
																	<Chip
																		tooltip
																		tooltip-text="Asset #"
																		small
																		:text="data.asset_relation.barcode"
																		color="blue darken-4"
																	></Chip>
																</p>
																<p class="mb-0 font-level-3">{{ data.asset_relation.name }}</p>
															</div>
														</div>
													</td>
													<td class="px-4 py-2">
														<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
															<Chip
																tooltip
																tooltip-text="Service #"
																small
																:text="data.service_relation.barcode"
																color="blue darken-4"
															></Chip>
															<Chip
																tooltip
																tooltip-text="Service Type"
																small
																class="mt-1"
																:text="data.service_relation.service_type_formatted"
																:color="
																	data.service_relation.service_type == 'on-site'
																		? 'brown darken-4'
																		: 'blue-grey darken-4'
																"
															></Chip>
															<Chip
																tooltip
																tooltip-text="Service Status"
																small
																:text="ObjectKey(data, 'service_relation.status_relation.text', 'N/A')"
																class="mt-1"
																:color="ObjectKey(data, 'service_relation.status_relation.color', 'N/A')"
															></Chip>
														</div>
													</td>
												</tr>
											</tbody>
										</template>
										<template v-else>
											<tr>
												<td colspan="2" align="center" height="93.75px" class="border-light-grey">
													<div class="py-2">
														<p class="mb-0 font-level-3 bold-600">Oops... Nothing Found</p>
													</div>
												</td>
											</tr>
										</template>
									</template>
								</table>
							</div>
							<br />
						</v-col>
					</v-row>
				</template>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn
				class="mx-2 custom-grey-border custom-bold-button"
				color="blue darken-4 white--text"
				tile
				:disabled="dataLoading"
				depressed
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import ApiService from "@/core/services/api.service";
import { toSafeInteger } from "lodash";

export default {
	data() {
		return {
			dataLoading: false,
			invoiceList: [],
			search: null,
			timeoutLimit: 0,
			timeout: null,
		};
	},
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Dialog,
		Chip,
		TextInput,
		ImageTemplate,
	},
	watch: {
		dialog(param) {
			if (param) {
				this.getInvoices();
			}
		},
	},
	methods: {
		getInvoices() {
			const _this = this;
			clearTimeout(_this.timeout);
			_this.timeout = setTimeout(function () {
				_this.dataLoading = true;
				_this.invoiceList = [];
				ApiService.query("invoice/approved", { search: _this.search })
					.then(({ data }) => {
						_this.invoiceList = data;
					})
					.catch((error) => {
						_this.$emit("error", error);
						_this.logError(error);
					})
					.finally(() => {
						_this.dataLoading = false;
					});
			}, _this.timeoutLimit);
		},
		selectInvoice({ uuid }) {
			this.$emit("select", uuid);
			this.$emit("close", true);
		},
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
